<template>
  <dashboard-layout>

    <template slot="heading">
      <v-toolbar-title class="d-none d-md-block">
        License Keys
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <license-filter v-if="!hasSelected" />
      <license-bulk-options v-if="hasSelected"></license-bulk-options>

    </template>

    <template slot="content">

      <loading-circular v-if="loading && !keys.length"></loading-circular>

      <v-alert type="error" v-if="error" rounded="0">{{ error }}</v-alert>

      <v-alert
        rounded="0"
        class="text-center"
        type="info"
        v-if="!keys.length && !loading && !error"
      >
        No data to show.
        <br>
        <v-btn class="ma-2" href="http://www.adopisoft.com/activation" target="_blank">
          Buy License
        </v-btn>
      </v-alert>

      <template v-if="keys.length && !error">

        <v-container>

          <h3 class="my-3" v-if="keys.length">
            <licenses-heading-text
              :total="total_items"
            ></licenses-heading-text>
          </h3>

          <v-list
            three-line
            nav
          >
            <v-list-item-group
              multiple
              v-model="selected"
              active-class="primary--text"
            >
              <template v-for="(k,i) in keys">
                <v-list-item
                  :key="i"
                  :value="k"
                  @contextmenu.prevent="longpress(k)"
                >
                  <template
                    v-slot:default="{ active }"
                  >
                    <rand-list-item-avatar
                      :str="k.code"
                      size="56"
                    >
                    </rand-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title v-text="k.code"></v-list-item-title>
                      <v-list-item-subtitle v-text="k.sku_str.toUpperCase()"></v-list-item-subtitle>
                      <v-list-item-subtitle v-text="k.available ? 'Available' : (k.expired ? 'Expired' : 'Used: '+k.device.display)"></v-list-item-subtitle>
                      <v-list-item-subtitle v-if="k.is_subscription">
                      (Subscription Expiry/Renewal:
                      {{k.renew_at | formatDateOnly}}
                      )
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action v-if="productKeySelectMode">
                      <v-icon v-if="!active" color="grey lighten-1">mdi-checkbox-blank-outline</v-icon>
                      <v-icon v-if="active" color="primary">mdi-checkbox-marked</v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>

              </template>
            </v-list-item-group>
          </v-list>

          <div v-if="!loading && keys.length" v-observe-visibility="reachedBottom">
          </div>

          <template
            v-if="page < total_pages || loading"
            class="text-center my-6"
          >
            <div class="text-center">
              <v-progress-circular indeterminate></v-progress-circular>
            </div>
          </template>

        </v-container>
      </template>


    </template>

  </dashboard-layout>
</template>

<script>

  import  { mapGetters, mapActions } from 'vuex'
  import LicenseFilter from '@/views/dashboard/license-keys/LicenseFilter'
  import LicensesHeadingText from '@/views/dashboard/license-keys/LicensesHeadingText'
  import LicenseBulkOptions from '@/views/dashboard/license-keys/LicenseBulkOptions'

  export default {
    name: 'LicenseKeys',
    components: {
      LicenseFilter,
      LicensesHeadingText,
      LicenseBulkOptions
    },
    data() {
      return {
        loading: true,
        error: null,
        page: 1,
        total_items: 0,
        total_pages: 0,
        selected: []
      }
    },
    created() {
      this.reset()
      this.load()
    },
    destroyed() {
      this.reset()
      this.resetProductKeysFilter()
    },
    methods: {
      reset() {
        this.clearProductKeys()
        this.loading = true
        this.page = 1
      },
      async load() {
        try {
          this.loading = true
          let { page } = this
          let { per_page, status, type, q } = this.productKeysFilter
          let { total_items, total_pages } = await this.fetchProductKeys({ page, per_page, status, q, type })
          this.total_items = total_items
          this.total_pages = total_pages
        } catch(e) {
          this.error = e
        } finally {
          this.loading = false
        }
      },
      reachedBottom(visible) {
        if (visible && this.page < this.total_pages && !this.loading) {
          this.page++
          this.load()
        }
      },
      longpress(pk) {
        pk.selected = true
        this.selected.push(pk)
        this.$store.commit('SET_PRODUCT_KEYS_SELECT_MODE', true)
      },
      ...mapActions(['fetchProductKeys', 'clearProductKeys', 'resetProductKeysFilter'])
    },
    computed: {
      hasSelected() {
        return this.selectedProductKeys.length > 0
      },
      keys() {
        return this.allProductKeys
      },
      ...mapGetters(['allProductKeys', 'selectedProductKeys', 'productKeysFilter', 'productKeySelectMode'])
    },
    watch: {
      productKeysFilter() {
        this.reset()
        this.load()
      },
      productKeySelectMode(mode) {
        if (mode && !this.selectedProductKeys.length && this.allProductKeys.length)
          this.selected.push(this.allProductKeys[0])
        else if (!mode && this.selectedProductKeys.length) {
          this.selected = []
          this.$store.commit('DESELECT_ALL_PRODUCT_KEYS')
        }
      },
      selected(pks) {
        const ids = pks.map(pk => pk.id)
        let all = this.allProductKeys

        if (this.productKeySelectMode) {
          all.forEach(p => {
            p.selected = ids.includes(p.id)
          })
          this.$store.commit('SET_PRODUCT_KEYS', all)
        } else {
          if (pks.length)
            this.$router.push({name: 'License', params: {id: ids[0]}})
        }
      }
    }
  }
</script>
