<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="450px"
  >

    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
        title="Transfer Licenses"
      >
        <v-icon>mdi-share</v-icon>
      </v-btn>
    </template>

    <v-card
      class="mx-auto"
    >
      <template v-if="!transfering && transferCount <= 0">
        <v-card-title>
          Transfer Licenses ({{ selectedProductKeys.length }})
        </v-card-title>
        <v-card-text>

          <v-form
            class="form"
            ref="form"
            v-model="valid"
          >
            <v-text-field
              label="Type the email of recepient"
              v-model="email"
              :rules="emailRules"
              :disabled="transfering"
              type="email"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="!valid"
            color="primary"
            @click="doTransfer"
          >
            Transfer
          </v-btn>
        </v-card-actions>
      </template>

      <!-- Transfer in progress -->
      <template v-if="transfering && progress < 100">
        <v-card-title>
          Transfering licenses ({{ transferCount }}/{{ selectedProductKeys.length }})...
        </v-card-title>
        <v-card-text>
          <v-progress-linear :value="progress"></v-progress-linear>
        </v-card-text>
      </template>

      <!-- Result transfers -->
      <template v-if="!transfering && progress > 99">
        <v-card-title>
          Transfer Summary
        </v-card-title>
        <v-card-text>
          <v-progress-linear value="100"></v-progress-linear>
          <v-list two-line>

            <template v-for="(t,i) in successTransfers">
              <v-list-item :key="i">
                <v-list-item-content>
                  <v-list-item-title>{{ t.product_key.code }}</v-list-item-title>
                  <span class="green--text">Transferred to {{ t.email }}</span>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-for="(f,i) in failedTransfers">
              <v-list-item :key="i">
                <v-list-item-content>
                  <v-list-item-title>{{ f.product_key.code }}</v-list-item-title>
                  <span class="red--text">
                    {{ f.error }}
                  </span>
                </v-list-item-content>
              </v-list-item>
            </template>


          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="done"
          >
            Done
          </v-btn>

          <v-btn
            v-if="failedTransfers.length"
            @click="reset"
          >
            Edit
          </v-btn>

        </v-card-actions>
      </template>
      <!-- End result transfers -->

    </v-card>

  </v-dialog>
</template>

<script>

  import promiseSeries from 'promise.series'
  import { transferLicense } from '@/services/http/license_transfers'
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'BulkLicenseTransfer',
    data: () => ({
      dialog: false,
      transferCount: 0,
      failedTransfers: [],
      successTransfers: [],
      transfering: false,
      valid: true,
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
    }),
    methods: {
      reset() {
        this.transferCount = 0
        this.failedTransfers = []
        this.successTransfers = []
        this.transfering = false

      },
      async transferOne(pk) {
        const t = { product_key: pk, email: this.email }
        try {
          await transferLicense(pk.id, this.email)
          this.successTransfers.push(t)
        } catch(e) {
          const et = { error: e, ...t }
          this.failedTransfers.push(et)
        } finally {
          this.transferCount ++
        }
      },
      done() {
        this.reset()
        this.clearProductKeys()
        this.selectAllProductKeys(false)
        this.resetProductKeysFilter()
        this.dialog = false
      },
      async doTransfer() {
        try {
          this.transfering = true
          await promiseSeries(this.selectedProductKeys.map(pk => () => this.transferOne(pk)))
          this.transfering = false
        } catch(e) {
          console.log(e)
        }
      },
      ...mapActions(['clearProductKeys', 'selectAllProductKeys', 'resetProductKeysFilter'])
    },
    computed: {
      progress() {
        return parseInt((this.transferCount / this.selectedProductKeys.length) * 100)
      },
      ...mapGetters(['selectedProductKeys'])
    }
  }
</script>
