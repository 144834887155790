<template>
  <span>

    <span v-if="!productKeysFilter.q && !productKeysFilter.status">
      All
    </span>
    <span v-if="productKeysFilter.status == 'available'">
      Available
    </span>
    <span v-if="productKeysFilter.status == 'unavailable'">
      Activated
    </span>
    <span v-if="productKeysFilter.type == 'business'">
      Business License
    </span>
    <span v-if="productKeysFilter.type == 'lite'">
      Lite License
    </span>

    <span v-if="productKeysFilter.q && productKeysFilter.entered">
      Search results for "{{ productKeysFilter.q }}"
    </span>

    <span v-if="!loading && !productKeysFilter.q">
      ({{ total || 0 }})
    </span>

  </span>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    props: ['loading', 'total'],
    computed: {
      ...mapGetters(['productKeysFilter'])
    }
  }
</script>
