<template>
  <v-btn icon title="Toggle Select" @click="toggleSelect" class="btn-no-active">
    <v-icon v-if="!productKeySelectMode">mdi-select</v-icon>
    <v-icon v-if="productKeySelectMode">mdi-selection-off</v-icon>
  </v-btn>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'ToggleSelectMode',
    data() {
      return {
        select: false
      }
    },
    methods: {
      toggleSelect() {
        var prev = this.productKeySelectMode
        var select = !prev
        this.setProductKeysSelectMode(select)
      },
      ...mapActions(['setProductKeysSelectMode', 'clearProductKeys'])
    },
    computed: {
      ...mapGetters(['selectedProductKeys', 'productKeySelectMode'])
    }
  }

</script>
