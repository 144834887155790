<template>

  <div class="license-filter">

    <v-text-field
      solo
      hide-details
      single-line
      placeholder="Search"
      v-model="q"
      @keydown="searchInput"
    ></v-text-field>

    <v-btn btn-no-active icon title="Show All" @click="showAll">
      <v-icon>mdi-view-list</v-icon>
    </v-btn>


    <v-dialog
      scrollable
      v-model="dialog"
      max-width="300px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          class="btn-no-active"
          title="More filter options"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-filter-menu-outline</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title>Filter Licenses</v-card-title>
        <v-divider></v-divider>

        <v-container fluid>
          <v-row no-gutters>
            <v-col>
              <v-card-text>

                <v-checkbox
                  v-model="status"
                  label="Available"
                  value="available"
                  hide-details
                ></v-checkbox>

                <v-checkbox
                  v-model="status"
                  label="Used"
                  value="unavailable"
                  hide-details
                ></v-checkbox>

              </v-card-text>
            </v-col>
            <v-col>
              <v-card-text>

                <v-checkbox
                  v-model="type"
                  label="Business"
                  value="business"
                  hide-details
                ></v-checkbox>

                <v-checkbox
                  v-model="type"
                  label="Lite"
                  value="lite"
                  hide-details
                ></v-checkbox>

              </v-card-text>
            </v-col>
          </v-row>

        </v-container>

        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="closeDialog"
          >
            Done
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

    <toggle-select-mode />

  </div>
</template>

<script>

  import ToggleSelectMode from '@/views/dashboard/license-keys/ToggleSelectMode'
  import { mapActions, mapGetters } from 'vuex'

  let prev_q = '';

  export default {
    components: {
      ToggleSelectMode
    },
    data: () => ({
      q: '',
      status: null,
      type: null,
      dialog: false,
      entered: 0,
    }),
    props: {
      value: {
        type: Object
      }
    },
    mounted() {
      let {q, status, type} = this.productKeysFilter
      this.q = q
      this.status = status
      this.type = type
      this.setProductKeysSelectMode(false)
    },
    methods: {
      reset() {
        this.entered = 0
        this.q = ''
        this.status = null
        this.type = null
        this.updateValue()
      },
      searchInput(e) {
        this.entered = 0
        if (this.q && (e.key === 'Enter' || e.keyCode === 13)) {
          this.entered = 1
          this.updateValue()
        } else if (!this.q && prev_q) {
          this.reset()
          this.updateValue()
        } else if (this.q && (e.key === "Escape" || e.keyCode === 27)) {
          this.q = ''
          this.updateValue()
        }
        prev_q = this.q
      },
      showAll() {
        this.reset()
        this.updateValue()
      },
      toggleType(type) {
        if (this.type == type)
          this.type = null
        else
          this.type = type
        this.updateValue()
      },
      toggleStatus(s) {
        if (this.status == s)
          this.status = null
        else
          this.status = s
        this.updateValue()
      },
      closeDialog() {
        this.dialog = 0
        this.setProductKeysSelectMode(false)
        this.updateValue()
      },
      clearFilter() {
        this.reset()
        this.closeDialog()
      },
      updateValue() {
        this.setProductKeysFilter({
          q: this.q,
          status: this.status,
          type: this.type,
          per_page: 15,
        })
      },
      ...mapActions([
        'setProductKeysFilter',
        'setProductKeysSelectMode'
      ])
    },
    computed: {
      ...mapGetters(['productKeysFilter'])
    }
  }
</script>

<style lang="scss" scoped>
  .license-filter {
    display: flex;
  }
</style>

