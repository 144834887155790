<template>
  <div class="license-bulk-options">

    <bulk-license-transfer></bulk-license-transfer>
    <toggle-select-mode></toggle-select-mode>

  </div>
</template>

<script>
  import BulkLicenseTransfer from '@/views/dashboard/license-keys/BulkLicenseTransfer'
  import ToggleSelectMode from '@/views/dashboard/license-keys/ToggleSelectMode'

  export default {
    name: 'LicenseBulkOptions',
    components: {
      BulkLicenseTransfer,
      ToggleSelectMode
    },
    
  }
</script>

<style lang="scss" scoped>
  .license-bulk-options {
    display: flex;
  }
</style>

